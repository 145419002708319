html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  overflow: visible !important;
}

#root {
  width: 100% !important;
  height: 100% !important;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 62px;
  background-color: rgba(0, 0, 0, .2);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
